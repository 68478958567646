<template>
  <button
    v-if="item"
    class="dropdownItem"
    :class="{
      'dropdownItem--small': small,
      'dropdownItem--trigger': trigger,
      'dropdownItem--selected': isSelected
    }"
    :disabled="disabled"
    @click.prevent="click"
  >
    <span v-if="label !== ''" class="dropdownItem__label">{{ label }}</span>
    <div class="dropdownItem__content">
      <OsIcon
        v-if="item.icon"
        :name="item.icon"
        class="dropdownItem__icon"
        :class="trigger || isSelected ? 'text-blue-600' : 'text-gray-400'"
      />
      <ImageCar
        v-else-if="item.vehicleImage"
        :vehicle="item.vehicleImage"
        :sizes="'100px'"
        :width="80"
        :height="48"
        densities="1"
      />
      <OsImage
        v-else-if="item.image"
        :key="item.image"
        :src="item.image"
        :alt="item.text"
        :width="88"
        :height="48"
        :banner-aspect-ratio="true"
      />
      <slot name="prefix" />
      <span v-if="item.text" class="dropdownItem__text">{{ item.text }}</span>
    </div>
    <slot />
  </button>
</template>

<script lang="ts">
import { IDropdownItem } from '~/types/dropdown'

export default {
  name: 'DropdownItem',
  props: {
    item: {
      type: Object as PropType<IDropdownItem>,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    trigger: {
      type: Boolean,
      required: false,
      default: false
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['click'],

  setup(props, { emit }) {
    const click = () => {
      emit('click')
    }

    return {
      click
    }
  }
}
</script>

<style lang="scss">
$dropdownItem-height: 48px;
$dropdownItem-height-small: 32px;

.dropdownItem {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 0;
  padding: rem(8);
  width: 100%;
  font-family: $mb-font-text-bold;
  font-size: rem(16);
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s linear;

  &:not(.dropdownItem--trigger):hover {
    background-color: $grey--lightest;
    transition: none;
  }

  .image,
  .car_image {
    display: none;
    margin-right: rem(16);
    background-color: $grey--lightest;
    max-width: 80px;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    overflow: hidden;
    @include viewport('md') {
      display: flex;
    }
  }

  .arrowIcon {
    display: none;
    flex-shrink: 0;
    margin-left: rem(4);
    transition: 0.2s linear;
  }
}

.dropdownItem--small {
  min-height: $dropdownItem-height-small;

  &.dropdownItem--trigger {
    height: ($dropdownItem-height-small + 8px);
    min-height: auto;
  }
}

.dropdownItem__label {
  position: absolute;
  z-index: 3;
  top: 8px;
  left: 16px;
  font-family: $mb-font-text;
  font-size: rem(14);
  color: $grey--darkest;
  pointer-events: none;

  ~ .dropdownItem__content {
    position: relative;
    top: 10px;

    .dropdownItem__text {
      position: relative;
      font-size: rem(18);
      color: $black--light;
    }
  }
}

.dropdownItem__content {
  display: flex;
  flex: 1;
  align-items: center;
  text-align: left;
  overflow: hidden;

  .dropdownItem__text {
    color: $grey--darkest;
  }
}

.dropdownItem__icon {
  margin-right: rem(4);
}

.dropdownItem__text {
  font-family: $mb-font-text;
}

.dropdownItem--trigger {
  padding: rem(8) rem(16);
  height: ($dropdownItem-height + 8px);
  background-color: $white;
  border: 1px solid $grey--light;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 2;

  .dropdownItem__text {
    overflow: hidden;
    text-overflow: ellipsis;
    color: $black--light;
  }

  .image + .dropdownItem__text {
    color: $blue--dark;
  }

  .arrowIcon {
    display: block;
  }

  .dropdown--expand-upwards & {
    .arrowIcon {
      transform: rotateX(-180deg);
    }
  }

  .dropdown--expanded.dropdown--expand-upwards & {
    .arrowIcon {
      transform: rotateX(0deg);
    }
  }
}

.dropdownItem.dropdownItem--selected {
  .dropdownItem__text {
    color: $blue--dark;
  }
}
</style>
