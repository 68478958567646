<template>
  <div
    v-click-outside="close"
    class="dropdown"
    :class="{
      'dropdown--expand-upwards': expandUpwards,
      'dropdown--expand-downwards': !expandUpwards,
      'dropdown--expanded': isExpanded,
      'dropdown--disabled': disabled,
      'dropdown--small': small
    }"
  >
    <InputDropdownItem
      :data-dropdown-item-title="selectedItem.text"
      :item="selectedItem"
      :disabled="disabled"
      :small="small"
      :label="label"
      trigger
      @click="expand"
    >
      <template #prefix
        ><span v-if="required" class="text-red-600 mr-1">*</span></template
      >
      <OsIcon
        name="Arrow"
        class="text-blue-600 transition-transform duration-300 ease-in-out"
        :class="isExpanded ? 'rotate-180' : ''"
      />
    </InputDropdownItem>
    <transition name="dropdown">
      <ul v-show="isExpanded" class="dropdown__items">
        <li
          v-for="item in items"
          :key="item.id"
          class="dropdown__item"
          :data-dropdown-item-title="item.text"
          :class="{
            'dropdown__item--selected':
              selectedItem && selectedItem.id === item.id,
            'dropdown__item--disabled': item.disabled
          }"
          @click.stop.prevent="select(item)"
        >
          <InputDropdownItem
            :item="item"
            :small="small"
            :is-selected="selectedItem && selectedItem.id === item.id"
          />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script lang="ts">
import clickOutside from '~/directives/clickOutside'
import { IDropdownItem } from '~/types/dropdown'

export default {
  directives: {
    clickOutside
  },
  props: {
    items: {
      type: Array as PropType<IDropdownItem[]>,
      required: true
    },
    modelValue: {
      type: Object as PropType<IDropdownItem>,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    keepExpanded: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showSelection: {
      type: Boolean,
      required: false,
      default: true
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    expandUpwards: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['close', 'expand', 'update:modelValue'],

  setup(props, { emit }) {
    const isExpanded = ref<boolean>(props.keepExpanded)

    const selectedItem = computed<IDropdownItem>({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)

        if (value.onSelect) {
          value.onSelect()
        }
      }
    })

    const close = () => {
      isExpanded.value = false
      emit('close')
    }

    const expand = () => {
      if (!isExpanded.value) {
        emit('expand')
      }

      isExpanded.value = !isExpanded.value
    }

    const select = (item: IDropdownItem) => {
      selectedItem.value = item

      // Don't close if the `expanded` prop is set to true
      if (!props.keepExpanded) {
        close()
      }
    }

    return {
      isExpanded,
      selectedItem,
      close,
      expand,
      select
    }
  }
}
</script>

<style lang="scss">
.dropdown-enter-active,
.dropdown-leave-active {
  transition:
    transform 0.5s $cubicEasing,
    opacity 0.2s linear;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
}

.dropdown {
  position: relative;
  transition: opacity 0.3s linear;
  z-index: 10;
}

.dropdown__item--selected {
  pointer-events: none;
}

.dropdown__item--disabled {
  pointer-events: none;

  .dropdownItem {
    filter: grayscale(100%);
    opacity: 0.3;

    .dropdownItem__text {
      color: $grey--dark;
    }
  }
}

.dropdown--expanded {
  .arrowIcon {
    transform: scale(-1);
  }
}

.dropdown--expand-downwards {
  .dropdown-enter,
  .dropdown-leave-to {
    transform: translateY(-40px);
  }

  .dropdown__items {
    top: calc(100% - 1px);
    border-radius: 0 0 4px 4px;
  }
}

.dropdown--expand-upwards {
  .dropdown-enter,
  .dropdown-leave-to {
    transform: translateY(40px);
  }

  .dropdown__items {
    bottom: calc(100% - 1px);
    border-radius: 4px 4px 0 0;
  }
}

.dropdown--disabled {
  pointer-events: none;
  opacity: 0.4;
}

.dropdown__items {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 rem(8);
  list-style: none;
  background-color: $white;
  border: 1px solid $grey--light;
  z-index: 1;
  overflow: auto;
}

.dropdown__item {
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid $grey--light;
  }
}
</style>
